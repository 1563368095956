<template>
  <div id="register.form">
      <username />
      <email />
      <company />
      <password />
      <password-repeat />
      <b-row>
          <b-col class="d-flex mt-2 justify-content-center"><register-button :active="valid" /></b-col>
      </b-row>
      <b-row>
          <b-col class="d-flex mt-2 justify-content-center"><p class="text-muted">Already have an account? <b-link :to="{ name: 'login' }">Login</b-link></p></b-col>
      </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import username from '../form/register/username'
import email from '../form/register/email'
import company from '../form/register/company'
import password from '../form/register/password'
import passwordRepeat from '../form/register/passwordRepeat'
import registerButton from '../components/registerButton'
import logic from '@/scripts';
export default {
    name: 'register.form',
    components: {
        username,
        email,
        company,
        password,
        passwordRepeat,
        registerButton
    },
    computed: {
        ...mapGetters({
            form: 'getRegisterForm'
        }),
        valid() {
            return logic.input.user.register.validate(this.form.username, this.form.email, this.form.company, this.form.password, this.form.passwordRepeat)
        }
    },
}
</script>