<template>
  <div id="user.register.index">
    <b-row class="m-0 p-4">
      <b-col md="3"></b-col>
      <b-col md="6">
        <b-card>
          <register-form />
        </b-card>
      </b-col>
      <b-col md="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import registerForm from "./form";
export default {
  name: "user.register.index",
  components: {
    registerForm,
  },
  computed: {
    ...mapGetters({
      authenticated: "isUserAuthenticated",
    }),
  },
  created() {
    if (this.authenticated) {
      this.$router.go(-1)
    }
  }
};
</script>

<style>
</style>