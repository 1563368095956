<template>
  <div id="user.form.register.company">
    <b-form-group class="mb-2" id="name-group">
      <label for="company-live">{{$t('user.form.register.company.label')}}:</label>
      <b-input-group>
        <b-form-input
          id="company-live"
          type="text"
          v-model="$v.company.$model"
          :state="validateState('company')"
          aria-describedby="company-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.company.$anyError" id="company-feedback">
        <small
          v-if="!$v.company.required"
          class="form-text text-danger"
        >{{$t('user.form.register.company.required')}}</small>
        <small
          v-if="!$v.company.minLength"
          class="form-text text-danger"
        >{{$t('user.form.register.company.minLength', { min: getLowerBoundary() })}}</small>
        <small
          v-if="!$v.company.maxLength"
          class="form-text text-danger"
        >{{$t('user.form.register.company.maxLength', { max: getUpperBoundary() })}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import logic from '@/scripts';
export default {
  name: "user.form.register.company",
  computed: {
    ...mapGetters({
      form: 'getRegisterForm'
    }),
    company: {
      get() {
        return this.form.company
      },
      set(value) {
        this.$store.commit("setRegisterFormCompany", value)
      }
    },
  },
  
  validations: {
    company: {
      required,
      minLength() {
        return (
          logic.input.user.register.company.minLength(this.form.company)
        );
      },
      maxLength() {
        return (
          logic.input.user.register.company.maxLength(this.form.company)
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundary() {
      return logic.input.sonic.project.name.min
    },
    getUpperBoundary() {
      return logic.input.sonic.project.name.max
    }
  }
};
</script>

<style>
</style>