<template>
  <div id="user.components.registerButton">
    <b-button variant="success" size="lg" :disabled="!active" @click="clicked()"
      ><i class="fas fa-user-plus"></i>
      {{ $t("user.form.register.buttons.register") }}</b-button
    >
  </div>
</template>

<script>
export default {
  name: "user.components.registerButton",
  props: ['active'],
  methods: {
    clicked() {
      this.$store.dispatch("register")
    },
  },
};
</script>

<style>
</style>